import { Button, Form, Input } from 'antd'
import '../ExerciseContent.scss'
import React, { useCallback, useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const ImageAdded = ({ setIsReflesh, setisWordAdded, selectedData, typeQue }) => {
    const [form] = Form.useForm();
    const [value, setValue] = useState(1);
    const { lessonId, lessonRoadId } = useParams();

    const onFinish = useCallback((value) => {
        const service = new Service();
        value["question_language_id"] = parseInt(localStorage.getItem("selectedLang"));
        value["answer_language_id"] = parseInt(localStorage.getItem("selectedTranslateLang"));
        value["type"] = typeQue;
        value["correnct_question"] = value.answer[0];
        if (selectedData) {
            value["exercise_id"] = selectedData.exercise_id;
            service.AdminService.UpdateExerciseQuestion(value, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    setIsReflesh((prev) => !prev);
                    setisWordAdded(false);
                    toast.success(GetWord("Added successfully."));
                } else {
                    toast.error(res.message);
                }
            })
        } else {
            service.AdminService.PostExercise({
                road_id: parseInt(lessonRoadId),
                title: "",
                id: parseInt(lessonId),
                type: "lesson",
            }).then((res2) => {
                if (!res2.error && res2.data) {
                    value["exercise_id"] = res2.data.id;
                    service.AdminService.PostExerciseQuestion(value).then((res) => {
                        if (!res.error && res.data) {
                            setIsReflesh((prev) => !prev);
                            setisWordAdded(false);
                            toast.success(GetWord("Added successfully."));
                        } else {
                            toast.error(res.message);
                        }
                    })
                } else {
                    toast.error(res2.message);
                }
            })
        }


    }, [lessonId, lessonRoadId, selectedData, setIsReflesh, setisWordAdded, typeQue])
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='WordsContent-BodyArea-AddWords-Modal'
            initialValues={selectedData}
        >

            <Form.Item name="question" label={<p className='WordsContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Word")}</p>} className='WordsContent-BodyArea-AddWords-Modal-WordsComment'>
                <Input type='input' placeholder="Word" className='WordsContent-BodyArea-AddWords-Modal-RulesComment' />
            </Form.Item>

            <Form.Item name={["answer", 0]} label={<p className='WordsContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Answer True")}</p>} className='WordsContent-BodyArea-AddWords-Modal-WordsComment'>
                <Input type='input' placeholder="Answer True" className='WordsContent-BodyArea-AddWords-Modal-RulesComment' />
            </Form.Item>

            <Form.Item name={["answer", 1]} label={<p className='WordsContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Answer False")}</p>} className='WordsContent-BodyArea-AddWords-Modal-WordsComment'>
                <Input type='input' placeholder="Answer False" className='WordsContent-BodyArea-AddWords-Modal-RulesComment' />
            </Form.Item>

            <Form.Item name={["answer", 2]} label={<p className='WordsContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Answer False")}</p>} className='WordsContent-BodyArea-AddWords-Modal-WordsComment'>
                <Input type='input' placeholder="Answer False" className='WordsContent-BodyArea-AddWords-Modal-RulesComment' />
            </Form.Item>

            <Form.Item className='WordsContent-BodyArea-AddWords-Modal-Button'>
                <Button htmlType='submit' className='WordsContent-BodyArea-AddWords-Modal-Button-Style'>
                    <p className='WordsContent-BodyArea-AddWords-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save WordsContent-BodyArea-AddWords-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ImageAdded