import React from 'react'
import Service from '../../../../Service';
import { GetWord } from '../../../../utils';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import './DeleteModal.scss'

const DeleteModal = ({ isModalOpen, setIsModalOpen, seletecedId, url, setIsReflesh, isreflesh }) => {
    const onDelete = () => {
        const service = new Service();
        service.AdminService.GenericDeleteItem(url, seletecedId).then((res) => {
            if (!res.error) {
                setIsReflesh((prev) => !prev);
                setIsModalOpen((prev) => !prev);
                toast.success(GetWord("Successfully deleted"));
            } else {
                setIsModalOpen((prev) => !prev);
                toast.error(GetWord("errorDelete"));
            }

        })
    }

    return (
        <Modal open={isModalOpen} destroyOnClose footer={null} closable={false}>
            <div className='deleteModal'>
                <div className='deleteModal-message'>
                    {GetWord("Do You Want To Delete?")}
                </div>
                <div className='deleteModal-button'>
                    <button className='btn' onClick={() => setIsModalOpen((prev) => !prev)}>{GetWord("No")}</button>

                    <button className='btn btn-primary' onClick={() => onDelete()}>{GetWord("Yes")}</button>
                </div>
            </div>
        </Modal >
    )
}


export default DeleteModal