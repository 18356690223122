import { Button, Form, Input } from 'antd';
import React, { useCallback } from 'react'
import './LoginPage.scss'
import { GetWord } from '../../../../../utils';
import Service from '../../../../../Service';
import { setToken } from '../../../../../stores/auth';
import { Encrypt } from '../../../../../utils/encrypted';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../../../../utils/firebase';

const LoginPage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = useCallback(async (value) => {
        const user = await login(value.email, value.password);
        if (user) {
            navigate("/");
        }
    }, [navigate]);


    return (
        <div className='Login'>
            <div className='Login-Header'>
                <img src={process.env.PUBLIC_URL + "/Assets/images/logo.png"} alt='logo' />
                <p>SUNDUK</p>
            </div>
            <div className='Login-form'>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}

                >
                    <Form.Item label={GetWord("UserName")}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: GetWord("UserNameRequired"),
                            },
                            {
                                type: 'email',
                                message: GetWord("NotMAil"),
                            },
                        ]}>
                        <Input placeholder={GetWord("UserName")}
                        />
                    </Form.Item>
                    <Form.Item label={GetWord("Password")} name="password" rules={[
                        {
                            required: true,
                            message: GetWord("PasswordRequired"),
                        },
                    ]} >
                        <Input.Password placeholder={GetWord("Password")} />
                    </Form.Item>
                    <Form.Item >
                        <Button htmlType="submit" danger>{GetWord("Login")}</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default LoginPage