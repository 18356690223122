import React, { useCallback, useEffect, useState } from 'react'
import './Packages.scss'
import { Button, Checkbox, Form, Input, Modal, Radio, Space, Table } from 'antd'
import { GetWord } from '../../../../utils';
import Service from '../../../../Service';
import LoadingPage from '../../../shared/LoadingPage/LoadingPage';
import PacketAddUpdateForm from './components/PacketAddUpdateForm';
import DeleteModal from '../../components/DeleteModal/DeleteModal';

const Packages = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReflesh, setIsReflesh] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [packagesList, setpackagesList] = useState([]);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value);
    })

    const getPacketList = useCallback((search) => {
        const service = new Service();
        service.AdminService.getPackages(search ? "search=" + search : "").then((res) => {
            if (!res.error && res.data) {
                setpackagesList(res.data)
            }
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        getPacketList();
    }, [getPacketList, isReflesh])

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const List = [
        {
            title: GetWord("Name"),
            dataIndex: 'name',
            render: (username, data) =>
                <p className='packages-BodyArea-List-NameArea-Name'>{username}</p>,
        },
        {
            title: GetWord('Id'),
            dataIndex: 'id',
            render: (text) =>
                <div className='packages-BodyArea-List-Id'>
                    <p className='packages-BodyArea-List-Id-Icon'>#</p>
                    <p className='packages-BodyArea-List-Id-Text'>{text}</p>
                </div>

        },
        {
            title: GetWord('Type'),
            dataIndex: 'type',
            render: (text) =>
                <div className='packages-BodyArea-List-Type'>
                    <i className='icon-map packages-BodyArea-List-Type-Icon' />
                    <p className='packages-BodyArea-List-Type-Text'>{text}</p>
                </div>

        },
        {
            title: GetWord('Unit'),
            dataIndex: 'included_units',
            render: (text) =>
                <div className='packages-BodyArea-List-Unit'>
                    <p className='packages-BodyArea-List-Unit-Text'>{text}</p>
                </div>
        },
        {
            title: GetWord('Dialogue'),
            dataIndex: 'included_dialogs',
            render: (text) =>
                <div className='packages-BodyArea-List-Unit'>
                    <p className='packages-BodyArea-List-Unit-Text'>{text}</p>
                </div>
        },
        {
            title: GetWord('Purchases'),
            dataIndex: 'purchases',
            render: (text) =>
                <div className='packages-BodyArea-List-Purchases'>
                    <i className='icon-shopping_bag packages-BodyArea-List-Purchases-Icon' />
                    <p className='packages-BodyArea-List-Purchases-Text'>{text}</p>
                </div>
        },
        {
            title: GetWord('Price'),
            dataIndex: 'price',
            render: (text) =>
                <div className='packages-BodyArea-List-Price'>
                    <p className='packages-BodyArea-List-Price-Text'>{text}</p>
                    <p className='packages-BodyArea-List-Price-Text'>₺</p>
                </div>
        },
        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='packages-BodyArea-List-ButtonArea'>
                    <button className='packages-BodyArea-List-ButtonArea-DeleteButton' onClick={() => {
                        setSelectedId(record?.id)
                        setIsDeleteModal(true);
                    }}>
                        <i className='icon-dangerous packages-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='packages-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='packages-BodyArea-List-ButtonArea-EditButton' onClick={() => {
                        setSelectedId(record)
                        setIsModalOpen(true);
                    }}>
                        <i className='icon-edit packages-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='packages-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button>
                </div>

        },
    ];

    const packageAdd = () => {
        setSelectedId(null)
        setIsModalOpen(true);
    };

    const add = () => {
        setIsModalOpen(false);
        setIsAdd(true);
    };
    const noCancel = () => {
        setIsModalOpen(false);
        setIsAdd(false);
    };




    return (
        <div className='packages'>

            <div className='packages-HeaderArea'>
                <div className='packages-HeaderArea'>
                    <div className='packages-HeaderArea-PageName'>{GetWord("Packages")}</div>
                </div>
                <div className='packages-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search packages-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a package")} />
                    <div className='packages-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => getPacketList(search)}
                            className='packages-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='packages-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt packages-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
                <div className='packages-HeaderArea-AddPackage'>
                    <div className='packages-HeaderArea-AddPackage-Button'>
                        <>
                            <Button className='packages-HeaderArea-AddPackage-Button-Style' type="primary" onClick={packageAdd}>
                                <p className='packages-HeaderArea-AddPackage-Button-Text'>{GetWord("Add Package")}</p>
                                <div className='packages-HeaderArea-AddPackage-Button-Icon'>
                                    <i className='icon-add' />
                                </div>
                            </Button>
                        </>

                    </div>
                </div>
            </div>


            <div className='packages-BodyArea'>
                <div className='packages-BodyArea-List'>
                    <Table
                        pagination={{ pageSize: 6 }}
                        // rowSelection={{
                        //     type: Checkbox,
                        //     ...rowSelection,

                        // }}
                        loading={{ spinning: loading, indicator: <LoadingPage small={true} /> }}
                        columns={List}
                        dataSource={packagesList}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>
            </div>

            <DeleteModal isModalOpen={isDeleteModal} setIsModalOpen={setIsDeleteModal} seletecedId={selectedId} url="packages" setIsReflesh={setIsReflesh} isReflesh={isReflesh} />

            <Modal destroyOnClose title="" open={isModalOpen} onCancel={noCancel} onOk={add} closable={false} className='packages'
                footer={null}
            >
                <PacketAddUpdateForm setIsModalOpen={setIsModalOpen} setIsReflesh={setIsReflesh} defaultValue={selectedId} />

            </Modal>
        </div>
    )
}

export default Packages