import React, { useCallback, useState } from 'react'
import { GetWord } from '../../../../../../utils';
import { Button, Form, Input, Radio, Space } from 'antd';
import Service from '../../../../../../Service';
import { toast } from 'react-toastify';

const LessonsRoadsAddEdit = ({ selectedData, lessonId, setUpdateList, setIsModalOpen }) => {
    const [form] = Form.useForm();

    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };


    const onFinish = useCallback((value) => {
        value["unit_id"] = parseInt(lessonId);
        const service = new Service();
        if (selectedData) {
            service.AdminService.updateLessonRoad(value, selectedData.id).then((res) => {
                if (res.error) {
                    toast.error(res.message)
                } else {
                    setIsModalOpen((prev) => !prev);
                    setUpdateList((prev) => !prev);
                    toast.success(GetWord("Added successfully."));
                }
            })
        } else {
            service.AdminService.postLessonRoad(value).then((res) => {
                if (res.error) {
                    toast.error(res.message)
                } else {
                    setIsModalOpen((prev) => !prev);
                    setUpdateList((prev) => !prev);
                    toast.success(GetWord("Added successfully."));
                }
            })
        }

    }, [lessonId, selectedData, setIsModalOpen, setUpdateList])

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={selectedData}
            className='lessons-AddUnit'
        >

            <Form.Item name="name" rules={[
                {
                    required: true,
                    message: GetWord("LessonNameRequired"),
                },
            ]}
                className='lessons-AddUnit-UnitName'
            >
                <Input placeholder={GetWord("Unit Name")} className='lessons-AddUnit-Input' />
            </Form.Item>
            <Form.Item name="subject" rules={[
                {
                    required: true,
                    message: GetWord("subjectRequired"),
                },
            ]}
                className='lessons-AddUnit-UnitName'
            >
                <Input placeholder={GetWord("Lesson Name")} className='lessons-AddUnit-Input' />
            </Form.Item>
            <Form.Item name="type" rules={[
                {
                    required: true,
                    message: GetWord("subjectRequired"),
                },
            ]}
                className='lessons-AddUnit-UnitName'
            >
                {/* <Input placeholder={GetWord("Type")} className='lessons-AddUnit-Input' /> */}
                <Radio.Group onChange={onChange} value={value} >
                    <Space direction="vertical">
                        <Radio value="rules" >{GetWord("Rules")}</Radio>
                        <Radio value="grammers" >{GetWord("Grammer")}</Radio>
                        <Radio value="words" >{GetWord("Words")}</Radio>
                        <Radio value="cities" >{GetWord("City")}</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <Form.Item>
                <Button className='lessons-AddUnit-Button-Style' htmlType="submit">
                    <p className='lessons-AddUnit-Button-Text'>{GetWord("Save")}<i className='icon-save lessons-AddUnit-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default LessonsRoadsAddEdit