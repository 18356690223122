import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { getLocalstorage } from "../utils";
import AuthLayout from "../layout/auth/AuthLayout";
import LoginPage from "../layout/auth/pages/PageLogin/Singin/LoginPage";

const AuthRouter = () => {
  const user = getLocalstorage("tkn");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/lessons");
    }
  }, [user, navigate]);

  return !user ? (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
    </Routes>
  ) : null;
};

export default AuthRouter;
