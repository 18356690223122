import { Button, Form, Input } from 'antd'
import '../GrammarContent.scss'
import React, { useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'

const CommentAdded = ({ selectData, setIsCommentAdded, setIsUpdate }) => {
    const [form] = Form.useForm();

    const onFinish = (value) => {
        const service = new Service();
        const entity = {
            description: value.description,
            incorrect_sentence: selectData.incorrect_sentence,
            sample_sentece: selectData.sample_sentece,
            title: selectData.title,
            road_id: selectData.road_id,
        }

        service.AdminService.updateLessonRoadGrammer(entity, selectData.id).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsCommentAdded((prev) => !prev);
                setIsUpdate((prev) => !prev);
            } else {
                toast.error(res.message)
            }
        })
    }
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='GrammarContent-BodyArea-AddGrammar-Modal'
            initialValues={selectData}

        >
            <Form.Item name="description" className='GrammarContent-BodyArea-AddGrammar-Modal-GrammarComment' label={<p className='GrammarContent-BodyArea-AddGrammar-Modal-GrammarComment-Title'>{GetWord("Dil Bilgisi Açıklaması")}</p>}>
                <TextArea type='textArea' placeholder={GetWord("Enter a description")} className='GrammarContent-BodyArea-AddGrammar-Modal-RulesComment' style={{
                    resize: 'none',
                }} />
            </Form.Item>

            <Form.Item className='GrammarContent-BodyArea-AddGrammar-Modal-Button'>
                <Button htmlType='submit' className='GrammarContent-BodyArea-AddGrammar-Modal-Button-Style'>
                    <p className='GrammarContent-BodyArea-AddGrammar-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save GrammarContent-BodyArea-AddGrammar-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CommentAdded