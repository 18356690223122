import { Button, Modal, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLessonContent } from '../../../../../../../stores/lessonContentdata';
import ImageAdded from './ImageAdded';
import WordAdded from './WordAdded';
import '../ExpressionContent.scss'
import { GetWord } from '../../../../../../../utils';

const AddWordImage = ({ setIsAddWordImage, setIsUpdate, selectedData }) => {

    const [isWordAdded, setIsWordAdded] = useState(false);
    const [isImageAdded, setIsImageAdded] = useState(false);

    useEffect(() => {
        if (selectedData) {
            if (selectedData.image_url) {
                setIsImageAdded(true);
                setIsAddWordImage(false);
            } else {
                setIsWordAdded(true);
                setIsAddWordImage(false);
            }
        }
    }, [selectedData, setIsAddWordImage])

    const add = () => {
        setIsAddWordImage(false);

        if (value === 1) {
            setIsWordAdded(true);
            setIsAddWordImage(false);
        }
        if (value === 2) {
            setIsImageAdded(true);
            setIsAddWordImage(false);


        }
    };
    const noCancel = () => {
        setIsAddWordImage(false);
        setIsWordAdded(false);
        setIsImageAdded(false);
    };

    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
        setIsAddWordImage(true); // Open the modal when the radio button changes
        setIsWordAdded(false);
        setIsImageAdded(false);
    };

    return (
        <div className='ExpressionContent'>
            <Modal destroyOnClose title="" open={isWordAdded} onCancel={noCancel} closable={false} className='ExpressionContent'
                footer={null}>
                <WordAdded setIsModalOpen={setIsWordAdded} setIsUpdate={setIsUpdate} selectedData={selectedData} />
            </Modal>
            <Modal destroyOnClose title="" open={isImageAdded} onCancel={noCancel} closable={false} className='ExpressionContent'
                footer={null}>
                <ImageAdded setIsModalOpen={setIsImageAdded} setIsUpdate={setIsUpdate} selectedData={selectedData} />
            </Modal>
            <div className='ExpressionContent-AddWords-UnitName'>
                <p className='ExpressionContent-AddWords-UnitName-Title'>{GetWord("Content Type")}</p>
            </div>
            <div className='ExpressionContent-AddWords-LessonDialogue' >
                <Radio.Group onChange={onChange} value={value} >
                    <Space direction="vertical">
                        <Radio value={1} >{GetWord("Word")}</Radio>
                        <Radio value={2} >{GetWord("Image")}</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className='ExpressionContent-HeaderArea-AddWords'>
                <Button onClick={add} className='ExpressionContent-AddWords-Button-Style'>
                    <p className='ExpressionContent-AddWords-Button-Text'>{GetWord("Add")}<i className='icon-add ExpressionContent-AddWords-Button-Text' /></p>
                </Button>
            </div>
        </div>
    )
}

export default AddWordImage
