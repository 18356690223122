import { Button, Form, Input } from 'antd'
import '../GrammarContent.scss'
import React, { useCallback, useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'

const FalseExample = ({ selectData, setIsFalseExample, setIsUpdate }) => {
    const [form] = Form.useForm();
    const onFinish = useCallback((value) => {
        const service = new Service();
        const entity = {
            description: selectData.description,
            incorrect_sentence: value.incorrect_sentence,
            sample_sentece: selectData.sample_sentece,
            title: selectData.title,
            road_id: selectData.road_id,
        }

        service.AdminService.updateLessonRoadGrammer(entity, selectData.id).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsFalseExample((prev) => !prev);
                setIsUpdate((prev) => !prev);
            } else {
                toast.error(res.message)
            }
        })
    }, [selectData?.description, selectData?.id, selectData?.sample_sentece, selectData?.road_id, selectData?.title, setIsFalseExample, setIsUpdate]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='GrammarContent-BodyArea-AddGrammar-Modal'
            initialValues={selectData}
        >
            <Form.Item name="incorrect_sentence" className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample' label={<p className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample-Title'>{GetWord("False Example")}</p>}>
                <Input placeholder={GetWord("Enter a description")} className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample' />
            </Form.Item>

            <Form.Item className='GrammarContent-BodyArea-AddGrammar-Modal-Button'>
                <Button htmlType='submit' className='GrammarContent-BodyArea-AddGrammar-Modal-Button-Style'>
                    <p className='GrammarContent-BodyArea-AddGrammar-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save GrammarContent-BodyArea-AddGrammar-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default FalseExample