import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './Lessons.scss'
import { Button, Checkbox, Input, Modal, Radio, Space, Table } from 'antd'

import { Link } from 'react-router-dom';
import { GetWord } from '../../../../utils';
import LessonAdd from './components/LessonAdd';
import Service from '../../../../Service';
import { useDispatch, useSelector } from 'react-redux';
import LoadingPage from '../../../shared/LoadingPage/LoadingPage';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { setIUnitName } from '../../../../stores/site';

const Lessons = () => {
    const [loading, setLoading] = useState(true);
    const [lessonList, setLessonList] = useState([]);
    const [updateList, setUpdateList] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const site = useSelector(state => state.site);
    const dispatch = useDispatch();
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value)
    });

    const getLessonList = useCallback((search) => {
        setLoading(true)
        const service = new Service();
        service.AdminService.getLessonLanguage(site.selectedLang + (search ? "?search=" + search : "")).then((res) => {
            if (!res.error && res.data) {
                setLessonList(res.data)
            }
            setLoading(false);
        })
    }, [site.selectedLang]);

    useEffect(() => {
        getLessonList();
    }, [getLessonList, updateList])

    // const rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //     },
    //     getCheckboxProps: (record) => ({
    //         name: record.name,
    //     }),
    // };


    const packageAdd = () => {
        setSelectedData(null);
        setIsModalOpen((prev) => !prev);
    };

    const noCancel = () => {
        setIsModalOpen(false);
    };

    const UpdateLesson = useCallback((data) => {
        setSelectedData(data);
        setIsModalOpen((prev) => !prev);
    }, [])

    const DeleteLesson = useCallback((data) => {
        setSelectedData(data);
        setIsDeleteModalOpen((prev) => !prev);
    }, [])


    const componentTable = useMemo(() => {
        const List = [
            {
                title: GetWord("Name"),
                dataIndex: 'name',
                render: (username, data) =>
                    <p className='lessons-BodyArea-List-NameArea-Name'>{username}</p>,
            },
            {
                title: GetWord('Id'),
                dataIndex: 'id',
                render: (text) =>
                    <div className='lessons-BodyArea-List-Id'>
                        <p className='lessons-BodyArea-List-Id-Icon'>#</p>
                        <p className='lessons-BodyArea-List-Id-Text'>{text}</p>
                    </div>

            },
            {
                title: GetWord('Type'),
                dataIndex: 'type',
                render: (text) =>
                    <div className='lessons-BodyArea-List-Type'>
                        <i className='icon-map lessons-BodyArea-List-Type-Icon' />
                        <p className='lessons-BodyArea-List-Type-Text'>{text}</p>
                    </div>

            },

            {
                title: "",
                dataIndex: 'content',
                render: (text, record) =>
                    <Link className='lessons-BodyArea-List-Content' onClick={() => dispatch(setIUnitName(record.name))} to={'LessonRoads/' + record.id}>
                        <i className='icon-folder lessons-BodyArea-List-Content-Icon' />
                        <p className='lessons-BodyArea-List-Content-Text'>{text}</p>
                    </Link>

            },

            {
                title: '',
                dataIndex: 'button',
                render: (text, record) =>
                    <div className='lessons-BodyArea-List-ButtonArea'>
                        <button className='lessons-BodyArea-List-ButtonArea-DeleteButton' onClick={() => DeleteLesson(record)} >
                            <i className='icon-dangerous lessons-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                            <p className='lessons-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                        </button>
                        <button className='lessons-BodyArea-List-ButtonArea-EditButton' onClick={() => UpdateLesson(record)}  >
                            <i className='icon-edit lessons-BodyArea-List-ButtonArea-EditButton-Icon' />
                            <p className='lessons-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                        </button></div>
            },
        ];

        return <Table
            // rowSelection={{
            //     type: Checkbox,
            //     ...rowSelection,
            // }}
            loading={{ spinning: loading, indicator: <LoadingPage small={true} /> }}
            columns={List}
            dataSource={lessonList}
            pagination={{
                pageSize: 5
            }}
            scroll={{ x: 'fit-content' }}
        />
    }, [DeleteLesson, UpdateLesson, lessonList, loading])

    return (
        <div className='lessons'>
            <div className='lessons-HeaderArea'>
                <div className='lessons-HeaderArea'>
                    <div className='lessons-HeaderArea-PageName'>{GetWord("Lessons")}</div>
                </div>
                <div className='lessons-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search lessons-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a lesson")} />
                    <div className='lessons-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => getLessonList(search)}
                            className='lessons-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='lessons-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt lessons-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
                <div className='lessons-HeaderArea-AddUnit'>
                    <div className='lessons-HeaderArea-AddUnit-Button'>
                        <Button className='lessons-HeaderArea-AddUnit-Button-Style' type="primary" onClick={packageAdd}>
                            <p className='lessons-HeaderArea-AddUnit-Button-Text'>{GetWord("Add Lesson")}</p>
                            <div className='lessons-HeaderArea-AddUnit-Button-Icon'>
                                <i className='icon-add' />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>

            <div className='lessons-BodyArea'>
                <div className='lessons-BodyArea-List'>
                    {componentTable}
                </div>
            </div>


            <Modal title="" open={isModalOpen} onCancel={noCancel} closable={false} className='lessons'
                footer={null} destroyOnClose>
                <LessonAdd selectedData={selectedData} setUpdateList={setUpdateList} setIsModalOpen={setIsModalOpen} />
            </Modal>

            <DeleteModal setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} url={"lesson"} seletecedId={selectedData?.id} setIsReflesh={setUpdateList} />
        </div>
    )
}

export default Lessons