import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Sidebar from './components/sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import './AdminLayout.scss'
import { Button, Form, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Service from '../../Service'
import Emoji from 'react-emoji-render'
import { toast } from 'react-toastify'
import { getInitLang, setIsSelectedLang, setSelectedLang, setSelectedTranslateLang } from '../../stores/site'


const AdminLayout = () => {
    const dispatch = useDispatch();
    const site = useSelector(state => state.site);

    useEffect(() => {
        if (site.selectedLang === 0) {
            dispatch(getInitLang());
        }
    }, [site, dispatch])
    const [form] = Form.useForm();
    const [list, setList] = useState([]);


    function unicodeToEmoji(unicode) {
        const codePoints = unicode.split(' ').map(hexStr => parseInt(hexStr.replace('U+', ''), 16));
        return String.fromCodePoint(...codePoints);
    }

    const getLessonList = useCallback(() => {
        const service = new Service();
        service.AdminService.GetLanguages().then((res) => {
            if (!res.error) {
                setList(res.data.map((item) => ({
                    value: item.id,
                    label: unicodeToEmoji(item.flag_code) + " " + item.name
                })));
            }
        });
    }, []);

    useEffect(() => {
        if (site.isSelectedLang)
            getLessonList();
    }, [site, getLessonList])

    const onFinish = useCallback((value) => {
        if (value?.lang !== value?.trans_lang) {
            dispatch(setSelectedLang(value.lang));
            dispatch(setSelectedTranslateLang(value.trans_lang));
            dispatch(setIsSelectedLang(false));
        }
        else {
            toast.error("Dil ile Tercüme Dili aynı olamaz!!!")
        }
    }, [dispatch]);

    const component = useMemo(() => {
        return <Modal title="Dil seçimi yapın" open={site.isSelectedLang} onCancel={onFinish} closable={false} className=''
            footer={null}>
            <Form
                // {...formItemLayout}
                layout="vertical"
                form={form}
                onFinish={onFinish}
            // initialValues={{
            //     layout: formLayout,
            // }}

            >
                <Form.Item label="Dil" name="lang">
                    <Select options={list} />
                </Form.Item>
                <Form.Item label="Tercüme Dil" name="trans_lang">

                    <Select
                        options={list} />
                </Form.Item>
                <Form.Item
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Button type="primary" htmlType="submit">
                        Seç
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    }, [form, list, onFinish, site.isSelectedLang])

    return (
        <div className='adminLayout'>
            <Sidebar />
            <main>
                <Outlet />
            </main>
            {component}
        </div>
    )
}

export default memo(AdminLayout);