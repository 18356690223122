import { Button, Form, Input } from 'antd'
import '../WordsContent.scss'
import React, { useCallback, useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const ImageAdded = ({ selectedData, setIsModalOpen, setIsUpdate }) => {
    const [form] = Form.useForm();
    const { lessonRoadId } = useParams();


    const onFinish = useCallback((value) => {
        const service = new Service();
        value["road_id"] = parseInt(lessonRoadId);
        if (selectedData) {
            value["description"] = selectedData.description;
            value["image_url"] = selectedData.imageUrl;

            service.AdminService.updateLessonRoadWords(value, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        } else {
            value["description"] = "";
            value["image_url"] = "";

            service.AdminService.postLessonRoadWords(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        }

    }, [lessonRoadId, selectedData, setIsModalOpen, setIsUpdate]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={selectedData}
        >
            <Form.Item name="word" className='WordsContent-BodyArea-AddWords-Modal-WordsComment' label={<p className='WordsContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Example")}</p>}>
                <Input type='input' placeholder="Example" className='WordsContent-BodyArea-AddWords-Modal-RulesComment' />
            </Form.Item>
            <Form.Item name="translate" className='WordsContent-BodyArea-AddWords-Modal-WordsComment' label={<p className='WordsContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Translation")}</p>}>
                <Input type='input' placeholder="Translation" className='WordsContent-BodyArea-AddWords-Modal-RulesComment' />
            </Form.Item>

            <Form.Item className='WordsContent-BodyArea-AddWords-Modal-Button'>
                <Button htmlType='submit' className='WordsContent-BodyArea-AddWords-Modal-Button-Style'>
                    <p className='WordsContent-BodyArea-AddWords-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save WordsContent-BodyArea-AddWords-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ImageAdded