import { Button, Form, Modal } from 'antd';
import React, { useCallback, useState } from 'react'
import BlockSelectedOk from './BlockSelectedOk';
import Service from '../../../../../Service';
import { toast } from 'react-toastify';
import { GetWord } from '../../../../../utils';

// Diğer importlar...

const BlockSelected = ({ blockSelectedModal, seletedData, selectedRowKeys, setIsRefresh, setIsYesBlockIt }) => {
  const [form] = Form.useForm();

  const yesBlockIt = useCallback(() => {
    const service = new Service();
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      selectedRowKeys.forEach((item) => {
        service.AdminService.BlockUser({ is_blocked: true }, item.id).then((res) => { });
      });
      blockSelectedModal(false);
      setIsYesBlockIt(true);
      setIsRefresh((prev) => !prev);

    }
    else {
      service.AdminService.BlockUser({ is_blocked: true }, seletedData?.user?.id).then((res) => {
        if (!res.error && res.data) {
          blockSelectedModal(false);
          setIsYesBlockIt(true);
          setIsRefresh((prev) => !prev);
        } else {
          toast.error(res.message)
        }
      })
    }




  }, [blockSelectedModal, selectedRowKeys, seletedData?.user?.id, setIsRefresh, setIsYesBlockIt]);

  const noCancel = () => {
    blockSelectedModal(false)
  };


  const onFinish = (value) => {
    console.log(value);
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className='users-BlockSelected'>
          <i className='icon-error users-BlockSelected-ModalIcon' />
          <p className='users-BlockSelected-Message'>Seçili {seletedData?.user?.is_blocked ? "kullanıcıların engelini kaldırmak" : "kullanıcıları engellemek"} istediğinizden emin misiniz?</p>

          <Button onClick={yesBlockIt} className='users-BlockSelected-YesBlockIt'>
            <p className='users-BlockSelected-YesBlockIt-Text'>Evet, {seletedData?.user?.is_blocked ? "engeli kaldır" : "engelle"}!</p>
          </Button>
          <Button htmlType="submit" onClick={noCancel} className='users-BlockSelected-NoCancel'>
            <p className='users-BlockSelected-NoCancel-Text'>Hayır, iptal et!</p>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BlockSelected;
