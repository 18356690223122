import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { getLocalstorage } from "../utils";
import AdminLayout from "../layout/admin/AdminLayout";
import Lessons from "../layout/admin/pages/Lessons/Lessons";
import Dialogue from "../layout/admin/pages/Dialogue/Dialogue";
import Users from "../layout/admin/pages/Users/Users";
import Packages from "../layout/admin/pages/Packages/Packages";
import ErrorPage from "../layout/admin/pages/ErrorPage/ErrorPage";
import LessonContent from "../layout/admin/pages/Lessons/LessonContent/LessonContent";
import DialogueContent from "../layout/admin/pages/Dialogue/DialogueContent/DialogueContent";
import ExpressionContent from '../layout/admin/pages/Lessons/LessonContent/ExpressionContent/ExpressionContent'
import GrammarContent from '../layout/admin/pages/Lessons/LessonContent/GrammarContent/GrammarContent'
import WordsContent from '../layout/admin/pages/Lessons/LessonContent/WordsContent/WordsContent'
import CitiesContent from '../layout/admin/pages/Lessons/LessonContent/CitiesContent/CitiesContent'
import ExerciseContent from '../layout/admin/pages/Lessons/LessonContent/ExerciseContent/ExerciseContent'
import LessonsRoads from "../layout/admin/pages/Lessons/LessonsRoads/LessonsRoads";
import Home from "../layout/admin/pages/home";


const PrivateRouter = () => {
  const navigate = useNavigate();
  const user = getLocalstorage("tkn");

  useEffect(() => {
    if (!user) {
      navigate("/auth");
    }
  }, [user, navigate]);
  return user ? (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<Home />} />
        <Route path="Lessons" >
          <Route index element={<Lessons />} />
          <Route path="LessonRoads/:lessonId" >
            <Route index element={<LessonsRoads />} />
            <Route path="LessonContent/:lessonRoadId">
              <Route index element={<LessonContent />} />
              <Route path="ExpressionContent" element={<ExpressionContent />} />
              <Route path="GrammarContent" element={<GrammarContent />} />
              <Route path="WordsContent" element={<WordsContent />} />
              <Route path="CitiesContent" element={<CitiesContent />} />
              <Route path="ExerciseContent" element={<ExerciseContent />} />
            </Route>
          </Route>
        </Route>
        <Route path="Dialogue" >
          <Route index element={<Dialogue />} />
          <Route path="Content/:id" element={<DialogueContent />} />
        </Route>
        <Route path="Users" element={<Users />} />
        <Route path="Packages" element={<Packages />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes >
  ) : null;
};

export default PrivateRouter;
