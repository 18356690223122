import { Button, Form, Input } from 'antd'
import '../CitiesContent.scss'
import React, { useCallback, useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'

const CommentAdded = ({ selectedData, setIsModalOpen, setIsUpdate }) => {
    const [form] = Form.useForm();


    const onFinish = useCallback((value) => {
        const service = new Service();
        const entity = {
            "description": value.description,
            "image_url": selectedData.image_url,
            "name": selectedData.name,
            "road_id": selectedData.road_id
        }
        service.AdminService.updateLessonRoadCities(entity, selectedData.id).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsModalOpen((prev) => !prev);
                setIsUpdate((prev) => !prev);
            } else {
                toast.error(res.message)
            }
        })
    }, [selectedData.id, selectedData.image_url, selectedData.name, selectedData.road_id, setIsModalOpen, setIsUpdate])
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='CitiesContent-BodyArea-AddCities-Modal'
            initialValues={selectedData}
        >
            <Form.Item name="description" className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment' label={<p className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment-Title'>{GetWord("City Description")}</p>}>
                <TextArea type='textArea' placeholder={GetWord("Enter a description")} className='CitiesContent-BodyArea-AddCities-Modal-RulesComment' style={{
                    resize: 'none',
                }} />
            </Form.Item>

            <Form.Item className='CitiesContent-BodyArea-AddCities-Modal-Button'>
                <Button htmlType='submit' className='CitiesContent-BodyArea-AddCities-Modal-Button-Style'>
                    <p className='CitiesContent-BodyArea-AddCities-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save CitiesContent-BodyArea-AddCities-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CommentAdded