import i18n from "i18next";
import Backend from "i18next-http-backend";

import { initReactI18next } from "react-i18next";
const backendOptions = {
  loadPath: "/locales/{{lng}}/{{ns}}.json",
  crossDomain: true,
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'tr', // Varsayılan dil
    interpolation: {
      escapeValue: false, // HTML etiketlerini çevirmek için false
    },
    backend: backendOptions,
  });


export default i18n;
