import axios from "axios";
import { getLocalstorage } from "../utils";
import { Decrypt } from "../utils/encrypted";
import stores from "../stores";
import { setlogout } from "../stores/auth";
import moment from "moment";
import { RefleshToken } from "../utils/firebase";
import { toast } from "react-toastify";

const globalApiURL = "https://api.mobsmile.com/sunduk/api/";

export default class baseService {
  postObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + (funcName ? ("/" + funcName) : "");
    return this.callServiceMethod(url, entity, "POST");
  }

  getObject(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + (funcName ? ("/" + funcName + (data ? ("?" + data) : "")) : "");
    return this.callServiceMethod(url, data, "GET");
  }

  putObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PUT");
  }

  patchObject(schemaName, funcName, entity, Id) {
    var url = globalApiURL + schemaName + "/" + funcName + (Id ? "/" + Id : "");
    return this.callServiceMethod(url, entity, "PATCH");
  }

  deleteObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "DELETE");
  }

  callServiceMethod(url, data, method, cancelToken) {
    const userDec = localStorage.getItem("user");
    const user = userDec ? JSON.parse(Decrypt(JSON.parse(userDec))) : null;
    const currentDate = moment().utc();

    if (!user) {
      stores.dispatch(setlogout());
    } else {
      const refreshTokenExpiration = moment(
        user.stsTokenManager.expirationTime
      );
      if (refreshTokenExpiration < currentDate) {
        const newtokn = RefleshToken(user.stsTokenManager.refreshToken);

      }
    }


    const tokenDec = localStorage.getItem("tkn");
    const token = tokenDec ? Decrypt(JSON.parse(tokenDec)) : null;

    var headers = {
      Authorization: token ? "Bearer " + JSON.parse(token) : "",
    };

    return this.callAnyServiceMethod(url, data, method, headers, cancelToken);
  }

  async callAnyServiceMethod(url, data, method, headers, cancelToken) {
    var authOptions = {
      method,
      url,
      data,
      headers,
      withCredentials: false,
      cancelToken,
    };

    return await axios(authOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);

        return error?.response?.data

      });
  }
}
