import { Button } from 'antd'
import React, { useState } from 'react'

const BlockSelectedOk = ({ setIsYesBlockIt, seletedData }) => {

    const noCancel = () => {
        setIsYesBlockIt(false);
    };

    return (
        <div>


            <div className='users-BlockSelected-Ok'>
                <i className='icon-task_alt users-BlockSelected-Ok-ModalIcon' />
                <p className='users-BlockSelected-Ok-Message'>Seçili {seletedData?.user?.is_blocked ? "kullanıcıların engeli başarıyla kaldırıldı" : "kullanıcılar başarıyla engellendi"}!</p>
                <Button type="primary" onClick={noCancel} className='users-BlockSelected-Okey'>
                    <p className='users-BlockSelected-Okey-Text'>Tamamdır.</p>
                </Button>
            </div>

        </div>
    )
}

export default BlockSelectedOk