import { Button, Form, Input, Radio, Space } from 'antd'
import React, { useCallback, useState } from 'react'
import '../Dialogue.scss'
import Service from '../../../../../Service';
import { toast } from 'react-toastify';
import { GetWord } from '../../../../../utils';

const DialogAdd = ({ selectedData, setIsUpdate, setIsModalOpen }) => {
    const selectedLang = localStorage.getItem("selectedLang");
    const selectedTranslateLang = localStorage.getItem("selectedTranslateLang");
    const [form] = Form.useForm();


    const onFinish = useCallback((value) => {
        const service = new Service();
        value["language_id"] = parseInt(selectedLang);
        value["translate_langugage_id"] = parseInt(selectedTranslateLang);
        if (selectedData) {
            service.AdminService.UpdateDialogs(value, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });
        } else {
            service.AdminService.PostDialogs(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        }
    }, [selectedData, selectedLang, selectedTranslateLang, setIsModalOpen, setIsUpdate]);

    return (
        <div className='dialogue-HeaderArea-AddUnit-Form'>
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={selectedData}
                className='dialogue-AddUnit'>
                <Form.Item name="name" className='dialogue-AddUnit-UnitName' >
                    <Input placeholder={GetWord("Dialog Name")} className='dialogue-AddUnit-Input' />
                </Form.Item  >
                <Form.Item className='dialogue-AddUnit-LessonDialogue' >
                    <Radio.Group value={1} >
                        <Space direction="horizontal">
                            <Radio value={2} disabled>{GetWord("Lesson")}</Radio>
                            <Radio value={1} >{GetWord("Dialog")}</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item >
                <Form.Item >
                    <Button htmlType='submit' className='dialogue-AddUnit-Button-Style'>
                        <p className='dialogue-AddUnit-Button-Text'>{GetWord("Save")}<i className='icon-save dialogue-AddUnit-Button-Text' /></p>
                    </Button>
                </Form.Item>
            </Form >
        </div >
    )
}

export default DialogAdd