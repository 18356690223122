import React, { memo, useCallback } from 'react'
import './sidebar.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { GetWord } from '../../../../utils'
import { setlogout } from '../../../../stores/auth'
import { useDispatch } from 'react-redux'

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exit = useCallback(() => {
    dispatch(setlogout())
    navigate("/auth")
  }, [dispatch, navigate])

  return (
    <div className='sidebar'>
      <div>
        <div className='sidebar-logo'>
          <img className='sidebar-logo-img' src={process.env.PUBLIC_URL + '/Assets/images/logo.png'} alt='logo' />
          <p className='sidebar-logo-text'>Sunduk</p>
        </div>
        <nav className='sidebar-nav'>
          <ul className='sidebar-nav-ul'>
            <li className='sidebar-nav-item'><NavLink className='sidebar-nav-link' to="/Lessons"><i className='icon-book_3' />
              <span className='sidebar-mobileweb'>{GetWord("Lessons")}</span></NavLink></li>
            <li className='sidebar-nav-item'><NavLink className='sidebar-nav-link' to="/Users"><i className='icon-group' />
              <span className='sidebar-mobileweb'>{GetWord("Users")}</span></NavLink></li>
            <li className='sidebar-nav-item'><NavLink className='sidebar-nav-link' to="/Dialogue"><i className='icon-chat' />
              <span className='sidebar-mobileweb'>{GetWord("Dialogue")}</span></NavLink></li>
            <li className='sidebar-nav-item'><NavLink className='sidebar-nav-link' to="/Packages"><i className='icon-shopping_bag' />
              <span className='sidebar-mobileweb'>{GetWord("Packages")}</span></NavLink></li>
          </ul>
        </nav>
      </div>
      <button className='sidebar-exit' onClick={() => exit()}>
        <i className='icon-logout' /> <span className='sidebar-mobileweb'>{GetWord("Exit")}</span>
      </button>

    </div>
  )
}

export default memo(Sidebar)