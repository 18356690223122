import React from "react";
import { useLottie } from "lottie-react";
import loading from "./loading_animation.json";
import './LoadingPage.scss'

const LoadingPage = ({ small }) => {
  const options = {
    animationData: loading,
    loop: true,
    style: {
      height: 54,
      width: 125,
      borderRadius: 20,
      background: "#fff",
      padding: "41px 16px",
    },
  };
  const { View } = useLottie(options);
  return <div className={"LoadingPage" + (small ? " LoadingPage-small" : "")}>{View}</div>;
};

export default LoadingPage;
