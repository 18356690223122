import { Button, Form, Input, Radio, Space } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GetWord } from '../../../../../utils';
import Service from '../../../../../Service';
import { toast } from 'react-toastify';

const LessonAdd = ({ setUpdateList, setIsModalOpen, selectedData }) => {
    const [form] = Form.useForm();
    const [value, setValue] = useState(1);

    useEffect(() => {
        if (selectedData) {
            form.setFieldsValue({
                name: selectedData.name,
            });
        }
    }, [form, selectedData])

    const onFinish = useCallback((req) => {
        const service = new Service();
        req["language_id"] = parseInt(localStorage.getItem("selectedLang"));
        req["translate_langugage_id"] = parseInt(localStorage.getItem("selectedTranslateLang"));

        if (selectedData) {
            service.AdminService.updateLesson(req, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    setIsModalOpen((prev) => !prev)
                    setUpdateList((prev) => !prev)
                } else {
                    toast.error(res.message)
                }
            })
        } else {
            service.AdminService.postLesson(req).then((res) => {
                if (!res.error && res.data) {
                    setIsModalOpen((prev) => !prev)
                    setUpdateList((prev) => !prev)
                } else {
                    toast.error(res.message)
                }
            })
        }
    }, [selectedData, setIsModalOpen, setUpdateList]);

    const addComp = useMemo(() => {
        return <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={selectedData}
            className='lessons-AddUnit'
        >

            <Form.Item name="name" rules={[
                {
                    required: true,
                    message: GetWord("LessonNameRequired"),
                },
            ]}
                className='lessons-AddUnit-UnitName'
            >
                <Input placeholder={GetWord("Lesson Name")} className='lessons-AddUnit-Input' />
            </Form.Item>
            <Radio.Group value={value} >
                <Space direction="horizontal">
                    <Radio value={1} >{GetWord("Lesson")}</Radio>
                    <Radio value={2} disabled>{GetWord("Dialog")}</Radio>
                </Space>
            </Radio.Group>

            <Form.Item>
                <Button className='lessons-AddUnit-Button-Style' htmlType="submit">
                    <p className='lessons-AddUnit-Button-Text'>{GetWord("Save")}<i className='icon-save lessons-AddUnit-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    }, [form, onFinish, selectedData, value])

    return (
        <div className='lessons-HeaderArea-AddUnit-Form'>
            {addComp}
        </div>
    )
}

export default LessonAdd