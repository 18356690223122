import React, { useCallback, useEffect, useState } from 'react'
import './LessonContent.scss'
import { Button, Checkbox, Input, Modal, Radio, Space, Table } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import AddRules from './RulesContent/components/AddRules';
import AddWordImage from './WordsContent/components/AddWordImage'
import AddGrammar from './GrammarContent/components/AddGrammar';
import AddCities from './CitiesContent/components/AddCities';
import AddExercise from './ExerciseContent/components/ExerciseAdded';

import Service from '../../../../../Service';
import { useDispatch, useSelector } from 'react-redux';
import { setContentData } from '../../../../../stores/lessonContentdata';

const LessonContent = () => {
    const [lessonContentOneData, setLessonContentOneData] = useState(null);
    const [isUpdate, setIsUpdate] = useState(null);
    const site = useSelector(state => state.site);
    const lessonContentdata = useSelector(state => state.lessonContentdata);

    const { lessonRoadId } = useParams();
    const dispatch = useDispatch();
    //#region LessonRoadContent çekme işlemi
    const getRoadsDetail = useCallback((e) => {
        const service = new Service();
        service.AdminService.getLessonRoadContent(lessonRoadId).then((res) => {
            if (!res.error && res.data && res.data.length > 0) {
                dispatch(setContentData(res.data[0]))
                setLessonContentOneData(res.data[0]);
            }
        })
    }, [dispatch, lessonRoadId])

    useEffect(() => {
        getRoadsDetail();
    }, [getRoadsDetail, isUpdate])
    //#endregion

    //#region  table işlemleri
    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder lessonsContent-BodyArea-List-Icon' />,
        },
        {
            title: GetWord("Content Type"),
            dataIndex: 'name',
            render: (username, data) =>
                <p className='lessonsContent-BodyArea-List-NameArea-Name'>{username}</p>,
        },

        {
            title: GetWord("Inside Contents"),
            dataIndex: 'content',
            render: (text, record) => {
                // record.name üzerinden ismi alabilir ve linkTo'yu belirleyebilirsiniz
                const linkTo = getLinkTo(record.name);

                return (
                    <Link className='lessonsContent-BodyArea-List-Content' to={linkTo}>
                        <i className='icon-folder lessonsContent-BodyArea-List-Content-Icon' />
                        <p className='lessonsContent-BodyArea-List-Content-Text'>{text}</p>
                    </Link>
                );
            }
        },

        // {
        //     title: '',
        //     dataIndex: 'button',
        //     render: (text, record) =>
        //         <div className='lessonsContent-BodyArea-List-ButtonArea'>
        //             <button className='lessonsContent-BodyArea-List-ButtonArea-DeleteButton' >
        //                 <i className='icon-dangerous lessonsContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
        //                 <p className='lessonsContent-BodyArea-List-ButtonArea-DeleteButton-Text'>Sil</p>
        //             </button>
        //             <button className='lessonsContent-BodyArea-List-ButtonArea-EditButton' >
        //                 <i className='icon-edit lessonsContent-BodyArea-List-ButtonArea-EditButton-Icon' />
        //                 <p className='lessonsContent-BodyArea-List-ButtonArea-EditButton-Text'>Düzenle</p>
        //             </button></div>
        // },
    ];

    const getLinkTo = (name) => {
        switch (name) {
            case GetWord("Выражения"):
                return 'ExpressionContent';
            case GetWord("Грамматика"):
                return 'GrammarContent';
            case GetWord("Слова"):
                return 'WordsContent';
            case GetWord("Город"):
                return 'CitiesContent';
            case GetWord("Упражнение"):
                return 'ExerciseContent';
            default:
                return '/Lessons/LessonContent';
        }
    };


    const ListData = [
        {
            icon: "",
            name: GetWord("Грамматика"),//Grammer
            content: GetWord("Contents"),
            button: '',
        },
        {
            icon: "",
            name: GetWord("Слова"),//Words
            content: GetWord("Contents"),
            button: '',
        },
        {
            icon: "",
            name: GetWord("Выражения"),//Expression
            content: GetWord("Contents"),
            button: '',
        },
        // {
        //     icon: "",
        //     name: GetWord("Rules"),
        //     content: GetWord("Contents"),
        //     button: '',
        // },
        {
            icon: "",
            name: GetWord("Город"),//City
            content: GetWord("Contents"),
            button: '',
        },
        {
            icon: "",
            name: GetWord("Упражнение"),//Exercise
            content: GetWord("Contents"),
            button: '',
        },
    ];
    //#endregion

    const [isModalOpen, setIsModalOpen] = useState(false);
    const packageAdd = () => {
        setIsModalOpen(true);
    };

    const [isAddRules, setIsAddRules] = useState(false);
    const [isAddWordImage, setIsAddWordImage] = useState(false);
    const [isAddGrammar, setIsAddGrammar] = useState(false);
    const [isAddCities, setIsAddCities] = useState(false);
    const [isAddExercise, setIsAddExercise] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const add = () => {
        setIsModalOpen(false);

        setValue(null);
        if (value === 1) {
            setIsAddRules(true);
        }
        if (value === 2) {
            setIsAddGrammar(true);
        }
        if (value === 3) {
            setIsAddWordImage(true);
        }
        if (value === 4) {
            setIsAddCities(true);
        }
        if (value === 5) {
            setIsAddExercise(true);
        }
    };
    const noCancel = () => {
        setIsModalOpen(false);
        setIsAdd(false);
        setIsAddRules(false);
        setIsAddGrammar(false);
        setIsAddWordImage(false);
        setIsAddCities(false);
        setIsAddExercise(false);
    };

    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='lessonsContent'>

            <div className='lessonsContent-HeaderArea'>
                <div className='lessonsContent-HeaderArea'>
                    <div className='lessonsContent-HeaderArea-PageName'><Link className="lessonsContent-prevlink" onClick={handleGoBack}>{GetWord("Lessons")} - {site.unitName} - {lessonContentOneData?.name}</Link></div>
                </div>
                {/* <div className='lessonsContent-HeaderArea-SearchFilter'>
                    <Input addonBefore={<i className='icon-search lessonsContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder="Ünite Ara" />
                    <div className='lessonsContent-HeaderArea-SearchFilter-Filter'>
                        <Button className='lessonsContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='lessonsContent-HeaderArea-SearchFilter-Filter-Text'> Filtrele   <i className='icon-filter_alt lessonsContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div> */}
                <div className='lessonsContent-HeaderArea-AddContent'>
                    <div className='lessonsContent-HeaderArea-AddContent-Button'>
                        <>
                            <Button className='lessonsContent-HeaderArea-AddContent-Button-Style' type="primary" onClick={packageAdd}>
                                <p className='lessonsContent-HeaderArea-AddContent-Button-Text'>{GetWord("Create Content")}</p>
                                <div className='lessonsContent-HeaderArea-AddContent-Button-Icon'>
                                    <i className='icon-add' />
                                </div>
                            </Button>

                            <Modal title="" open={isModalOpen} onCancel={noCancel} onOk={add} closable={false} className='lessonsContent'
                                footer={[
                                    <Button onClick={add} className='lessonsContent-AddContent-Button-Style'>
                                        <p className='lessonsContent-AddContent-Button-Text'>{GetWord("Add")}<i className='icon-add lessonsContent-AddContent-Button-Text' /></p>
                                    </Button>,
                                ]}
                            >
                                <div className='lessonsContent-AddContent'>
                                    <div className='lessonsContent-AddContent-UnitName'>
                                        <p className='lessonsContent-AddContent-UnitName-Title'>{GetWord("Content Type")}</p>
                                    </div>
                                    <div className='lessonsContent-AddContent-LessonDialogue' >
                                        <Radio.Group onChange={onChange} value={value} >
                                            <Space direction="vertical">


                                                {
                                                    lessonContentdata.type === "rules" ? <Radio value={1} >{GetWord("Rules")}</Radio> : null
                                                }

                                                {
                                                    lessonContentdata.type === "grammers" ? <Radio value={2} >{GetWord("Grammer")}</Radio> : null
                                                }
                                                {
                                                    lessonContentdata.type === "words" ? <Radio value={3} >{GetWord("Words")}</Radio> : null
                                                }
                                                {
                                                    lessonContentdata.type === "cities" ? <Radio value={4} >{GetWord("City")}</Radio> : null
                                                }


                                                <Radio value={5} >{GetWord("Exercise")}</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Modal>
                        </>

                    </div>
                </div>
            </div>


            <Modal title="" open={isAddRules} onCancel={noCancel} closable={false} className='RulesContent'
                footer={null}>
                <AddRules setIsModalOpen={setIsAddRules} setIsUpdate={setIsUpdate} />
            </Modal>

            <Modal title="" open={isAddGrammar} onCancel={noCancel} closable={false} className='GrammerContent'
                footer={null}>
                <AddGrammar />
            </Modal>

            <Modal title="" open={isAddWordImage} onCancel={noCancel} closable={false} className='WordsContent'
                footer={null}>
                <AddWordImage setIsAddWordImage={setIsAddWordImage} />
            </Modal>

            <Modal title="" open={isAddCities} onCancel={noCancel} closable={false} className='CitiesContent'
                footer={null}>
                <AddCities />
            </Modal>
            <Modal title="" open={isAddExercise} onCancel={noCancel} closable={false} className='ExerciseContent'
                footer={null}>
                <AddExercise setIsAddExercise={setIsAddExercise} />
            </Modal>




            <div className='lessonsContent-BodyArea'>
                <div className='lessonsContent-BodyArea-List'>
                    <Table
                        columns={List}
                        dataSource={ListData}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>

            </div>

        </div>
    )
}

export default LessonContent