import baseService from "./baseService";

export default class AdminService extends baseService {
    constructor() {
        super();
        this.schemaName = "admin";
    }

    login(entity) {
        return this.postObject(this.schemaName, "login", entity);
    }

    /**
     * 
     * @param {*} burası offset ve limit almalıdır. örn offset=1&limit=10
     * @returns user bilgisi dönmektedir. UserArray
     */
    getUserList(entity) {
        return this.getObject(this.schemaName, "user-list", entity);
    }


    /**
     * 
     * @param {*} userId userın Sistemdeki IDsi.
     * @returns userın tüm bilgilerini dönmektedir.
     */
    getUserDetail(userId) {
        return this.getObject(this.schemaName, "user-detail/" + userId?.toString());
    }

    /**
     * token sahibi admini döner.
     * @returns 
     */
    getMe() {
        return this.getObject(this.schemaName, "me");
    }

    /**
     * tüm dersleri dönmektedir. dil fark etmez.
     * @returns tüm dersleri dönmektedir. dil fark etmez.
     */
    getLessons() {
        return this.getObject(this.schemaName, "lessons");
    }

    /**
     * 
     * @param {*} entity name,language_id, translate_language_id içermelidir.
     * @returns 
     */
    postLesson(entity) {
        return this.postObject(this.schemaName, "lessons", entity);
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} id 
     * @returns 
     */
    updateLesson(entity, id) {
        return this.patchObject(this.schemaName, "lesson", entity, id)
    }

    /**
     * seçilen dilin derslerini dönmektedir.
     * @param {*} languageId dil idsi
     * @returns seçilen dilin derslerini dönmektedir.
     */
    getLessonLanguage(languageId) {
        return this.getObject(this.schemaName, "lessons/" + languageId);
    }

    /**
     * 
     * @param {*} lessonId Dersin ıdsi
     * @returns geriye dersin roadslarını döner
     */
    getLessonRoads(lessonId) {
        return this.getObject(this.schemaName, "lesson-roads/" + lessonId);
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    postLessonRoad(entity) {
        return this.postObject(this.schemaName, "lessons/roads", entity);
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} roadId 
     * @returns 
     */
    updateLessonRoad(entity, roadId) {
        return this.patchObject(this.schemaName, "lesson/road/" + roadId, entity);
    }

    /**
     * 
     * @param {*} roadId rooad id
     * @returns roadların detayını dönmektedir.
     */
    getLessonRoadContent(roadId, entity) {
        return this.getObject(this.schemaName, "lesson-road-contents/" + roadId, entity);
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    postLessonRoadRules(entity) {
        return this.postObject(this.schemaName, "lessons/roads/rules", entity);
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} rulesID 
     * @returns 
     */
    updateLessonRoadRules(entity, rulesID) {
        return this.patchObject(this.schemaName, "lessons/roads/rules/" + rulesID, entity);
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    postLessonRoadCities(entity) {
        return this.postObject(this.schemaName, "lessons/roads/cities", entity);
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} CityID 
     * @returns 
     */
    updateLessonRoadCities(entity, CityID) {
        return this.patchObject(this.schemaName, "lessons/roads/cities/" + CityID, entity);
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    postLessonRoadGrammer(entity) {
        return this.postObject(this.schemaName, "lessons/roads/grammer", entity);
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} CityID 
     * @returns 
     */
    updateLessonRoadGrammer(entity, CityID) {
        return this.patchObject(this.schemaName, "lessons/roads/grammer/" + CityID, entity);
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    postLessonRoadWords(entity) {
        return this.postObject(this.schemaName, "lessons/roads/words", entity);
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} CityID 
     * @returns 
     */
    updateLessonRoadWords(entity, CityID) {
        return this.patchObject(this.schemaName, "lessons/roads/words/" + CityID, entity);
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    getSubscriptions(entity) {
        return this.getObject(this.schemaName, "subscriptions");
    }

    /**
     * 
     * @returns 
     */
    getPackages(entity) {
        return this.getObject(this.schemaName, "packages", entity)
    }

    /**
     * 
     * @param {*} entity 
     * @returns 
     */
    postPackages(entity) {
        return this.postObject(this.schemaName, "packages", entity)
    }

    /**
     * 
     * @param {*} entity 
     * @param {*} packageId 
     * @returns 
     */
    updatePackages(entity, packageId) {
        return this.patchObject(this.schemaName, "packages/" + packageId, entity)
    }

    /**
     * 
     * @param {*} url 
     * @param {*} id 
     * @returns 
     */
    GenericDeleteItem(url, id) {
        return this.deleteObject(this.schemaName, url + "/" + id)
    }

    GetDialogsLanguage(languageId) {
        return this.getObject(this.schemaName, "dialogs/" + languageId);
    }

    PostDialogs(entity) {
        return this.postObject(this.schemaName, "dialog", entity);
    }
    UpdateDialogs(entity, dialogId) {
        return this.patchObject(this.schemaName, "dialog/" + dialogId, entity);
    }

    GetDialogRoad(DialogRoadID) {
        return this.getObject(this.schemaName, "dialog-road/" + DialogRoadID);
    }


    PostDialogsRoad(entity) {
        return this.postObject(this.schemaName, "dialog-road", entity);
    }

    PostDialogPersonal(entity) {
        return this.postObject(this.schemaName, "dialog-example-users", entity);
    }

    GetDialogPersonal() {
        return this.getObject(this.schemaName, "dialog-example-users");
    }

    UpdateDialogRoad(roadId, entity) {
        return this.patchObject(this.schemaName, "dialog/road/" + roadId, entity);
    }

    GetExercise(unitId, entity) {
        return this.getObject(this.schemaName, "exercises/search/" + unitId, entity);
    }

    PostExercise(entity) {
        return this.postObject(this.schemaName, "exercises", entity);
    }

    PostExerciseQuestion(entity) {
        return this.postObject(this.schemaName, "exercise/questions", entity);
    }

    UpdateExerciseQuestion(entity, quesitonId) {
        return this.patchObject(this.schemaName, "exercise/questions/" + quesitonId, entity)
    }

    BlockUser(entity, userId) {
        return this.patchObject(this.schemaName, "user/block/" + userId?.toString(), entity);
    }

    fileUpload(entity) {
        return this.postObject("file-upload", "", entity)
    }


    /**
     * 
     * @returns 
     */
    GetLanguages() {
        return this.getObject("languages")
    }

}