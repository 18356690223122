import { Button, Form, Input } from 'antd'
import '../RulesContent.scss'
import React, { useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const AddRules = ({ setIsModalOpen, setIsUpdate, selectData }) => {
    const [form] = Form.useForm();
    const { lessonRoadId } = useParams();

    const onFinish = (value) => {
        value["road_id"] = parseInt(lessonRoadId);
        const service = new Service();
        if (selectData) {
            value["id"] = selectData.id;
            service.AdminService.updateLessonRoadRules(value, selectData.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });

        } else {
            service.AdminService.postLessonRoadRules(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });
        }

    }
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='RulesContent-BodyArea-AddRules-Modal'
            initialValues={selectData}
        >
            <Form.Item name="title" className='RulesContent-BodyArea-AddRules-Modal-FalseExample' label={<p className='RulesContent-BodyArea-AddRules-Modal-FalseExample-Title'>{GetWord("Title")}</p>}>
                <Input placeholder={GetWord("Enter a title")} className='RulesContent-BodyArea-AddRules-Modal-FalseExample' />
            </Form.Item>
            <Form.Item name="description" className='RulesContent-BodyArea-AddRules-Modal-RulesComment' label={<p className='RulesContent-BodyArea-AddRules-Modal-RulesComment-Title'>{GetWord("Rule Explanation")}</p>}>
                <TextArea type='textArea' placeholder={GetWord("Enter a description")} className='RulesContent-BodyArea-AddRules-Modal-RulesComment' style={{
                    resize: 'none',
                }} />
            </Form.Item>
            <Form.Item name="sample_sentece" className='RulesContent-BodyArea-AddRules-Modal-TrueExample' label={<p className='RulesContent-BodyArea-AddRules-Modal-TrueExample-Title'>{GetWord("True Example")}</p>}>
                <Input placeholder={GetWord("Enter a description")} className='RulesContent-BodyArea-AddRules-Modal-TrueExample' />
            </Form.Item>
            <Form.Item name="incorrect_sentence" className='RulesContent-BodyArea-AddRules-Modal-FalseExample' label={<p className='RulesContent-BodyArea-AddRules-Modal-FalseExample-Title'>{GetWord("False Example")}</p>}>
                <Input placeholder={GetWord("Enter a description")} className='RulesContent-BodyArea-AddRules-Modal-FalseExample' />
            </Form.Item>
            <Form.Item className='RulesContent-BodyArea-AddRules-Modal-Button'>
                <Button className='RulesContent-BodyArea-AddRules-Modal-Button-Style' htmlType="submit">
                    <p className='RulesContent-BodyArea-AddRules-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save RulesContent-BodyArea-AddRules-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default AddRules