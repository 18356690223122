import { Button, Form, Input } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'

const CommentAdded = ({ selectData, setIsModalOpen, setIsUpdate }) => {
    const [form] = Form.useForm();

    const onFinish = useCallback((value) => {
        const service = new Service();
        const entity = {
            description: value.description,
            image_url: selectData?.image_url,
            translate: selectData?.translate,
            word: selectData?.word,
            road_id: selectData.road_id,
        }

        service.AdminService.updateLessonRoadWords(entity, selectData.id).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsModalOpen((prev) => !prev);
                setIsUpdate((prev) => !prev);
            } else {
                toast.error(res.message)
            }
        })
    }, [selectData?.id, selectData?.image_url, selectData?.road_id, selectData?.translate, selectData?.word, setIsModalOpen, setIsUpdate]);
    useEffect(() => {
        if (selectData) {

        }
    }, [selectData])
    return (
        <div className='RulesContent-BodyArea-AddRules-Modal'>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={selectData}
            >
                <Form.Item name="description" className='RulesContent-BodyArea-AddRules-Modal-RulesComment' label={<p className='RulesContent-BodyArea-AddRules-Modal-RulesComment-Title'>{GetWord("RulesContentDesc")}</p>}>
                    <TextArea type='textArea' placeholder={GetWord("Enter a description")} className='RulesContent-BodyArea-AddRules-Modal-RulesComment' style={{
                        resize: 'none',
                    }} />
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" className='RulesContent-BodyArea-AddRules-Modal-Button RulesContent-BodyArea-AddRules-Modal-Button-Style' htmlType="submit">
                        <p className='RulesContent-BodyArea-AddRules-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save RulesContent-BodyArea-AddRules-Modal-Button-Text' /></p>
                    </Button>
                </Form.Item>
            </Form>
        </div >
    )
}

export default CommentAdded