import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/Lessons")
    }, [])
    return (
        <div>dashboard tasarımı yok</div>
    )
}

export default Home