import i18n from "../translate/i18n";


/**
 * Returns i18n Translate
 * @param {string} value  the word you want to translate
 */

export const GetWord = (value) => {
  return i18n.t(value);
};

export const getLocalstorage = (value) => {
  return JSON.parse(localStorage.getItem(value));
};

export const setLocalstorage = (value, data) => {
  localStorage.setItem(value, JSON.stringify(data));
};

export const removeLocalstorage = (value) => {
  localStorage.removeItem(value);
};

export const randomGuid = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}