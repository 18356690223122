import React, { useCallback, useEffect, useState } from 'react'
import './CitiesContent.scss'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../../utils';
import { Button, Input, Modal, Table } from 'antd';
import AddCities from './components/AddCities.js';
import CommentAdded from './components/CommentAdded';
import ImageAdded from './components/ImageAdded.js';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonContent } from '../../../../../../stores/lessonContentdata.js';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal.jsx';


const CitiesContent = () => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommentAdded, setIsCommentAdded] = useState(false);
    const [isTrueExample, setIsTrueExample] = useState(false);
    const [isFalseExample, setIsFalseExample] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const cities = useSelector(state => state.lessonContentdata.cities);
    const name = useSelector((state) => state.lessonContentdata.name);
    const { lessonRoadId } = useParams();
    const dispatch = useDispatch();
    const site = useSelector(state => state.site);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value);
    })

    useEffect(() => {
        dispatch(getLessonContent(lessonRoadId))
    }, [dispatch, lessonRoadId, isUpdate])


    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder CitiesContent-BodyArea-List-Icon' />,
        },

        {
            title: GetWord("Header"),
            dataIndex: 'name',
            render: (header, data) =>
                <p className='CitiesContent-BodyArea-List-Name'>{header}</p>
        },
        {
            title: GetWord('Explanation'),
            dataIndex: 'description',
            render: (text, record) =>
                <Link className='CitiesContent-BodyArea-List-Explanation' onClick={() => commentAdded(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("DescriptionAdded") : ""}</p>
                </Link>

        },
        {
            title: GetWord('Image'),
            dataIndex: 'image_url',
            render: (text, record) =>
                <Link className='CitiesContent-BodyArea-List-Image' onClick={() => trueExample(record)}>
                    <i className='icon-image' />
                    <p className=''>{text.length > 0 ? GetWord("VisualAdded") : GetWord("NoVisualAdded")}</p>
                </Link>

        },
        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='CitiesContent-BodyArea-List-ButtonArea'>
                    <button className='CitiesContent-BodyArea-List-ButtonArea-DeleteButton' onClick={() => onDelete(record)}>
                        <i className='icon-dangerous CitiesContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='CitiesContent-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='CitiesContent-BodyArea-List-ButtonArea-EditButton' onClick={() => CitiesAdd(record)}>
                        <i className='icon-edit CitiesContent-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='CitiesContent-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];


    const CitiesAdd = useCallback((value) => {
        setSelectedData(value)
        setIsModalOpen(true);
    }, []);

    const noCancel = () => {
        setIsModalOpen(false);
        setIsCommentAdded(false);
        setIsTrueExample(false);
        setIsFalseExample(false);
    };
    const commentAdded = useCallback((value) => {
        setSelectedData(value)
        setIsCommentAdded(true);
    }, []);

    const trueExample = useCallback((value) => {
        setSelectedData(value)
        setIsTrueExample(true);
    }, []);

    const onDelete = useCallback((value) => {
        setSelectedData(value)
        setIsDeleteModalOpen(true);
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='CitiesContent'>

            <div className='CitiesContent-HeaderArea'>
                <div className='CitiesContent-HeaderArea'>
                    <div className='CitiesContent-HeaderArea-PageName'><Link className="CitiesContent-prevlink" onClick={handleGoBack}>{GetWord("Lessons")} - {site.unitName} - {name} - {GetWord("Cities")}</Link></div>
                </div>
                <div className='CitiesContent-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search CitiesContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a city")} />
                    <div className='CitiesContent-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => dispatch(getLessonContent(lessonRoadId, search ? "search=" + search : ""))}
                            className='CitiesContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='CitiesContent-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt CitiesContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
            </div>

            <>
                <Modal destroyOnClose title="" open={isCommentAdded} onCancel={noCancel} closable={false} className='CitiesContent'
                    footer={null}>
                    <CommentAdded selectedData={selectedData} setIsModalOpen={setIsCommentAdded} setIsUpdate={setIsUpdate} />
                </Modal>

                <Modal destroyOnClose title="" open={isTrueExample} onCancel={noCancel} closable={false} className='CitiesContent'
                    footer={null}>
                    <ImageAdded selectedData={selectedData} setIsModalOpen={setIsTrueExample} setIsUpdate={setIsUpdate} />
                </Modal>

            </>

            <div className='CitiesContent-BodyArea'>
                <div className='dialogue-BodyArea-List'>
                    <Table
                        columns={List}
                        dataSource={cities}
                        scroll={{ x: 'fit-content' }}
                        pagination={{
                            pageSize: 5
                        }}
                    />
                    <div className='CitiesContent-BodyArea-AddCities-Button'>
                        <Button className='CitiesContent-BodyArea-AddCities-Button-Style' type="primary" onClick={() => CitiesAdd(null)}>
                            <p className='CitiesContent-BodyArea-AddCities-Button-Text'>{GetWord("Add City")}</p>
                            <div className='CitiesContent-BodyArea-AddCities-Button-Icon' >
                                <i className='icon-add' />
                            </div>
                        </Button>

                        <>
                            <Modal title="" destroyOnClose open={isModalOpen} onCancel={noCancel} closable={false} className='CitiesContent'
                                footer={null}>
                                <AddCities selectedData={selectedData} setIsModalOpen={setIsModalOpen} setIsUpdate={setIsUpdate} />
                            </Modal>
                        </>
                    </div>
                </div>
            </div>
            <DeleteModal setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} url="lessons/roads/cities" setIsReflesh={setIsUpdate} seletecedId={selectedData?.id} />
        </div>
    )
}

export default CitiesContent