import React from 'react'
import './AuthLayout.scss'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {
  return (
    <div className='AuthLayout'>
      <div className='AuthLayout-container'>
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout