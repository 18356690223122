import { Button, Form, Input, Upload, message } from 'antd'
import '../ExpressionContent.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { GetWord, randomGuid } from '../../../../../../../utils'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ImgUpload } from '../../../../../../../utils/firebase';
import Service from '../../../../../../../Service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const WordAdded = ({ selectedData, setIsModalOpen, setIsUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [form] = Form.useForm();
    const { lessonRoadId } = useParams();

    useEffect(() => {
        if (selectedData) {
            setImageUrl(selectedData.image_url)
        }
    }, [selectedData])

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };


    const onFinish = useCallback((value) => {
        const service = new Service();
        value["road_id"] = parseInt(lessonRoadId);
        value["image_url"] = imageUrl;
        if (selectedData) {
            value["description"] = selectedData.description;
            value["word"] = selectedData.word;
            service.AdminService.updateLessonRoadWords(value, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        } else {
            value["description"] = "";
            value["word"] = "";
            service.AdminService.postLessonRoadWords(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        }

    }, [imageUrl, lessonRoadId, selectedData, setIsModalOpen, setIsUpdate]);



    const handleChange = useCallback(async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            const service = new Service();
            const formData = new FormData();
            formData.append('file', info.file.originFileObj);
            service.AdminService.fileUpload(formData).then((res) => {
                if (res.data && !res.error) {
                    setImageUrl(res.data.url);
                }
            })
        }
    }, []);
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Resim Yükle
            </div>
        </div>
    );



    return (
        <div className='ExpressionContent-BodyArea-AddWords-Modal'>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className='ExpressionContent-BodyArea-AddWords-Modal-Image'
                initialValues={selectedData}
            >
                <Form.Item label={<p className='ExpressionContent-BodyArea-AddWords-Modal-TrueExample-Title'>{GetWord("Image")}</p>}>
                    <>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://httpstat.us/200"
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </>
                </Form.Item>

                <Form.Item name="translate" className='ExpressionContent-BodyArea-AddWords-Modal-WordsComment' label={<p className='ExpressionContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Translation")}</p>}>
                    <Input type='input' placeholder="Translation" className='ExpressionContent-BodyArea-AddWords-Modal-RulesComment' />
                </Form.Item>


                <Form.Item className='ExpressionContent-BodyArea-AddWords-Modal-Button'>
                    <Button htmlType='submit' className='ExpressionContent-BodyArea-AddWords-Modal-Button-Style'>
                        <p className='ExpressionContent-BodyArea-AddWords-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save ExpressionContent-BodyArea-AddWords-Modal-Button-Text' /></p>
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default WordAdded