import { configureStore } from "@reduxjs/toolkit";
import siteReducer from "./site";
import authReducer from "./auth";
import lessonContentdataReducer from './lessonContentdata'

export default configureStore({
  reducer: {
    site: siteReducer,
    auth: authReducer,
    lessonContentdata: lessonContentdataReducer
  },
});
