import { Button, Form, Input } from 'antd'
import '../GrammarContent.scss';
import React, { useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import { useParams } from 'react-router-dom';
import Service from '../../../../../../../Service';
import { toast } from 'react-toastify';

const AddGrammar = ({ setIsModalOpen, setIsUpdate, selectData }) => {
    const [form] = Form.useForm();
    const { lessonRoadId } = useParams();

    const onFinish = (value) => {
        value["road_id"] = parseInt(lessonRoadId);
        const service = new Service();
        if (selectData) {
            value["id"] = selectData.id;
            service.AdminService.updateLessonRoadGrammer(value, selectData.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });

        } else {
            service.AdminService.postLessonRoadGrammer(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });
        }
    }
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='GrammarContent-BodyArea-AddGrammar-Modal'
            initialValues={selectData}
        >
            <Form.Item name="title" className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample' label={<p className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample-Title'>{GetWord("Title")}</p>}>
                <Input placeholder={GetWord("Enter a title")} className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample' />
            </Form.Item>
            <Form.Item name="description" className='GrammarContent-BodyArea-AddGrammar-Modal-GrammarComment' label={<p className='GrammarContent-BodyArea-AddGrammar-Modal-GrammarComment-Title'>{GetWord("Grammer Explanation")}</p>}>
                <TextArea type='textArea' placeholder={GetWord("Enter a description")} className='GrammarContent-BodyArea-AddGrammar-Modal-GrammarComment' style={{
                    resize: 'none',
                }} />
            </Form.Item>
            <Form.Item name="sample_sentece" className='GrammarContent-BodyArea-AddGrammar-Modal-TrueExample' label={<p className='GrammarContent-BodyArea-AddGrammar-Modal-TrueExample-Title'>{GetWord("True Example")}</p>}>
                <Input placeholder={GetWord("Enter a description")} className='GrammarContent-BodyArea-AddGrammar-Modal-TrueExample' />
            </Form.Item>
            <Form.Item name="incorrect_sentence" className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample' label={<p className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample-Title'>{GetWord("False Example")}</p>}>
                <Input placeholder={GetWord("Enter a description")} className='GrammarContent-BodyArea-AddGrammar-Modal-FalseExample' />
            </Form.Item>

            <Form.Item className='GrammarContent-BodyArea-AddGrammar-Modal-Button'>
                <Button htmlType='submit' className='GrammarContent-BodyArea-AddGrammar-Modal-Button-Style'>
                    <p className='GrammarContent-BodyArea-AddGrammar-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save GrammarContent-BodyArea-AddGrammar-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default AddGrammar