import { createSlice } from "@reduxjs/toolkit";
import { useCallback } from "react";
import Service from "../Service";

export const lessonContentdata = createSlice({
    name: "lessonContentdata",
    initialState: {
        grammers: [],
        cities: [],
        rules: [],
        words: [],
        name: "",
        type: "",
        id: 0,
        fulldata: null
    },
    reducers: {
        setContentData: (state, action) => {
            state.grammers = action.payload.grammers;
            state.cities = action.payload.cities;
            state.rules = action.payload.rules;
            state.words = action.payload.words;
            state.name = action.payload.name;
            state.id = action.payload.id;
            state.type = action.payload.type;
            state.fulldata = action.payload;
        },

    },
});

export const { setContentData } = lessonContentdata.actions;

export default lessonContentdata.reducer;

export function getLessonContent(value, search) {
    return async (dispatch) => {
        const service = new Service();
        service.AdminService.getLessonRoadContent(value, search).then((res) => {
            if (!res.error && res.data && res.data.length > 0) {
                dispatch(setContentData(res.data[0]))
            }
        })
    }
}

