import React, { useState, useCallback, useEffect } from 'react'
import './DialogueContent.scss'
import { Button, Checkbox, Flex, Form, Input, Modal, Radio, Space, Table, Upload } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import UnitAdd from '../components/UnitAdd';
import LoadingPage from '../../../../shared/LoadingPage/LoadingPage';
import Service from '../../../../../Service'
import { useParams } from 'react-router-dom';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import PersonelAdd from '../components/PersonelAdd';
import { useDispatch, useSelector } from 'react-redux';

const DialogueContent = () => {
    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPersonelModal, setIsPersonelModal] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isReflesh, setIsReflesh] = useState(false);
    const [isRefleshPersonal, setIsRefleshPersonal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [personelList, setPersonelList] = useState([]);
    const { id } = useParams();
    const site = useSelector(state => state.site);
    const [search, setSearch] = useState(null);

    const searchText = useCallback((e) => {
        setSearch(e.target.value)
    });

    const getDialogRoad = useCallback((search) => {
        const service = new Service();
        service.AdminService.GetDialogRoad(id + (search ? "?search=" + search : "")).then((res) => {
            if (!res.error && res.data) {
                setLoading(false)
                setListData(res.data)
            }
        })
    }, [id])


    useEffect(() => {
        getDialogRoad()
    }, [getDialogRoad, isReflesh])

    const deleteData = useCallback((value) => {
        setSelectedData(value);
        setIsDeleteModalOpen(true);
    }, []);

    const updateData = useCallback((value) => {
        setSelectedData(value);
        setIsModalOpen(true);
    }, [])

    const getPersonelList = useCallback(() => {
        const service = new Service();
        service.AdminService.GetDialogPersonal().then((res) => {
            if (!res.error && res.data) {
                setPersonelList(res.data.map((item) => {
                    return ({
                        value: item.id, label: item.name
                    })
                }))
            }
        })
    }, []);

    useEffect(() => {
        getPersonelList()
    }, [getPersonelList, isRefleshPersonal]);


    const List = [
        {
            title: GetWord("Icon"),
            dataIndex: 'icon_url',
            render: (icon_url, data) =>
                <img className='DialogueContent-BodyArea-List-NameArea-Image' src={icon_url} alt={data.name} />,
        },
        {
            title: GetWord("Name"),
            dataIndex: 'name',
            render: (name, data) =>
                <p className='DialogueContent-BodyArea-List-NameArea-Name'>{name}</p>,
        },
        // {
        //     title: GetWord('Person 1'),
        //     dataIndex: 'person1',
        //     render: (person) =>
        //         <div className='DialogueContent-BodyArea-List-Person1'>
        //             <p className='DialogueContent-BodyArea-List-Person1-Name'>{person}</p>
        //             <Link>
        //                 <p className='DialogueContent-BodyArea-List-Person1-Photo'>Fotoğraf</p>
        //             </Link>
        //             <Link>
        //                 <p className='DialogueContent-BodyArea-List-Person1-Dialogue'>Diyalogları</p>
        //             </Link>
        //         </div>
        // },
        // {
        //     title: GetWord('Person 2'),
        //     dataIndex: 'person2',
        //     render: (person2) =>
        //         <div className='DialogueContent-BodyArea-List-Person2'>
        //             <p className='DialogueContent-BodyArea-List-Person2-Name'>{person2}</p>
        //             <Link>
        //                 <p className='DialogueContent-BodyArea-List-Person2-Photo'>Fotoğraf</p>
        //             </Link>
        //             <Link>
        //                 <p className='DialogueContent-BodyArea-List-Person2-Dialogue'>Diyalogları</p>
        //             </Link>
        //         </div>
        // },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='DialogueContent-BodyArea-List-ButtonArea'>
                    <button className='DialogueContent-BodyArea-List-ButtonArea-DeleteButton' onClick={() => deleteData(record)}>
                        <i className='icon-dangerous DialogueContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='DialogueContent-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='DialogueContent-BodyArea-List-ButtonArea-EditButton' onClick={() => updateData(record)}>
                        <i className='icon-edit DialogueContent-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='DialogueContent-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];

    const personelAdd = () => {
        setIsPersonelModal(true);
    }
    const noCancel = () => {
        setIsModalOpen(false);
        setIsPersonelModal(false);
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='DialogueContent'>

            <div className='DialogueContent-HeaderArea'>
                <div className='DialogueContent-HeaderArea'>
                    <div className='DialogueContent-HeaderArea-PageName'><Link className="ExerciseContent-prevlink" onClick={handleGoBack}>{GetWord("Dialogs")} - {site.dialogName}</Link></div>
                </div>
                <div className='DialogueContent-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search DialogueContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a dialog")} />
                    <div className='DialogueContent-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => getDialogRoad(search)}
                            className='DialogueContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='DialogueContent-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt DialogueContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
                <div className='DialogueContent-HeaderArea-AddDialogue'>
                    <div className='DialogueContent-HeaderArea-AddDialogue-Button'>

                        <>
                            <Button className='DialogueContent-HeaderArea-AddDialogue-Button-Style' type="primary" onClick={personelAdd}>
                                <p className='DialogueContent-HeaderArea-AddDialogue-Button-Text'>{GetWord("Add Speaker")}</p>
                                <div className='DialogueContent-HeaderArea-AddDialogue-Button-Icon'>
                                    <i className='icon-add' />
                                </div>
                            </Button>
                        </>
                        <>
                            <Button className='DialogueContent-HeaderArea-AddDialogue-Button-Style' type="primary" onClick={() => updateData(null)}>
                                <p className='DialogueContent-HeaderArea-AddDialogue-Button-Text'>{GetWord("Add Dailog")}</p>
                                <div className='DialogueContent-HeaderArea-AddDialogue-Button-Icon'>
                                    <i className='icon-add' />
                                </div>
                            </Button>

                            <Modal title="" open={isModalOpen} destroyOnClose onCancel={noCancel} closable={false} className='DialogueContent'
                                footer={null}>

                                <UnitAdd personelList={personelList} setIsReflesh={setIsReflesh} setIsModalOpen={setIsModalOpen} selectedData={selectedData} />

                            </Modal>

                            <Modal title="" open={isPersonelModal} destroyOnClose onCancel={noCancel} closable={false} className='DialogueContent'
                                footer={null}>
                                <PersonelAdd setIsPersonelModal={setIsPersonelModal} setIsRefleshPersonal={setIsRefleshPersonal} />
                            </Modal>
                        </>
                    </div>
                </div>
            </div>

            <div className='DialogueContent-BodyArea'>
                <div className='DialogueContent-BodyArea-List'>
                    <Table
                        // rowSelection={{
                        //     // type: Checkbox,
                        //     // ...rowSelection,
                        // }}
                        scroll={{ x: 'fit-content' }}
                        columns={List}
                        dataSource={listData}
                        loading={{ spinning: loading, indicator: <LoadingPage small={true} /> }}

                    />
                </div>

            </div>
            <DeleteModal isModalOpen={isDeleteModalOpen} setIsModalOpen={setIsDeleteModalOpen} url={'dialog/road'} setIsReflesh={setIsReflesh} seletecedId={selectedData?.id} />
        </div>
    )
}

export default DialogueContent