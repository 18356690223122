import { Button, Modal, Radio, Space } from 'antd'
import '../ExerciseContent.scss'
import React, { useEffect, useState } from 'react'

import VoiceAdded from './VoiceAdded';
import WordAdded from './WordAdded';
import GapAdded from './GapAdded';
import { GetWord } from '../../../../../../../utils';

const ExerciseAdded = ({ setIsAddExercise, setIsReflesh, selectedData }) => {
    const [isWordAdded, setisWordAdded] = useState(false);
    const [isVoiceAdded, setisVoiceAdded] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isGapAdded, setisGapAdded] = useState(false);

    const [isExerciseAdded, setIsExerciseAdded] = useState(false);
    const [value, setValue] = useState(1);

    useEffect(() => {
        if (selectedData) {
            if (selectedData.type === "choose_corrent_translation") {
                setisWordAdded(true);
                setIsAddExercise(false);

            } else if (selectedData.type === "gap_filing") {
                setisGapAdded(true);
                setIsAddExercise(false);
            }
            else if (selectedData.type === "listening") {
                setisVoiceAdded(true);
                setIsAddExercise(false);
            }
        }
    }, [selectedData, setIsAddExercise])

    const add = () => {
        setIsExerciseAdded(false);
        setIsAddExercise(false);

        setValue(null);
        if (value === 1) {
            setisWordAdded(true);
        }
        if (value === 2) {
            setisVoiceAdded(true);
        }

        if (value === 3) {
            setisGapAdded(true);
        }

    };

    const noCancel = () => {
        setIsExerciseAdded(false);
        setIsAdd(false);
        setisWordAdded(false);
        setisVoiceAdded(false);
        setisGapAdded(false);
    };


    const onChange = (e) => {
        setValue(e.target.value);
    };
    return (
        <div className='ExerciseContent-AddWords'>

            <Modal destroyOnClose title="" open={isWordAdded} onCancel={noCancel} closable={false} className='ExerciseContent'
                footer={null}>
                <WordAdded typeQue={"choose_corrent_translation"} selectedData={selectedData} setIsReflesh={setIsReflesh} setisWordAdded={setisWordAdded} />
            </Modal>

            <Modal destroyOnClose title="" open={isVoiceAdded} onCancel={noCancel} closable={false} className='ExerciseContent'
                footer={null}>
                <VoiceAdded selectedData={selectedData} setIsReflesh={setIsReflesh} setisWordAdded={setisVoiceAdded} />
            </Modal>
            <Modal destroyOnClose title="" open={isGapAdded} onCancel={noCancel} closable={false} className='ExerciseContent'
                footer={null}>
                <WordAdded typeQue={"gap_filing"} selectedData={selectedData} setIsReflesh={setIsReflesh} setisWordAdded={setisGapAdded} />
            </Modal>


            <div className='ExerciseContent-AddWords-UnitName'>
                <p className='ExerciseContent-AddWords-UnitName-Title'>{GetWord("Content Type")}</p>
            </div>
            <div className='ExerciseContent-AddWords-LessonDialogue' >
                <Radio.Group onChange={onChange} value={value} >
                    <Space direction="vertical">
                        <Radio value={1} >{GetWord("Word")}</Radio>
                        <Radio value={2} >{GetWord("Audio")}</Radio>
                        <Radio value={3} >{GetWord("Fill In The Blank")}</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className='ExerciseContent-AddWords-Button'>
                <Button onClick={add} className='ExerciseContent-AddWords-Button-Style'>
                    <p className='ExerciseContent-AddWords-Button-Text'>{GetWord("Add")}<i className='icon-add ExerciseContent-AddWords-Button-Text' /></p>
                </Button>
            </div>
        </div>
    )
}

export default ExerciseAdded