import React, { useCallback, useEffect, useState } from 'react'
import './Dialogue.scss'
import { Button, Checkbox, Input, Modal, Radio, Space, Table } from 'antd'

import { Link } from 'react-router-dom';
import { GetWord } from '../../../../utils';
import DialogAdd from './components/DialogAdd';
import LoadingPage from '../../../shared/LoadingPage/LoadingPage';
import Service from '../../../../Service';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { setDialogName } from '../../../../stores/site';

const Dialogue = () => {
    const [loading, setLoading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(true);
    const [listData, setListData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value)
    });

    const dispatch = useDispatch();

    const selectedLang = localStorage.getItem("selectedLang");


    const getDialogueList = useCallback((search) => {
        const service = new Service();
        service.AdminService.GetDialogsLanguage(selectedLang + (search ? "?search=" + search : "")).then((res) => {
            if (!res.error && res.data) {
                setListData(res.data);
                setLoading(false)
            }
        })
    }, [selectedLang])

    useEffect(() => {
        getDialogueList();
    }, [getDialogueList, isUpdate])

    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder lessonsContent-BodyArea-List-Icon' />,
        },

        {
            title: GetWord("Name"),
            dataIndex: 'name',
            render: (username, data) =>
                <p className='dialogue-BodyArea-List-NameArea-Name'>{username}</p>
        },
        {
            title: GetWord('Id'),
            dataIndex: 'id',
            render: (text) =>
                <div className='dialogue-BodyArea-List-Id'>
                    <p className='dialogue-BodyArea-List-Id-Icon'>#</p>
                    <p className='dialogue-BodyArea-List-Id-Text'>{text}</p>
                </div>

        },
        {
            title: GetWord('Type'),
            dataIndex: 'type',
            render: (text) =>
                <div className='dialogue-BodyArea-List-Type'>
                    <i className='icon-chat dialogue-BodyArea-List-Type-Icon' />
                    <p className='dialogue-BodyArea-List-Type-Text'>{text}</p>
                </div>

        },

        {
            title: "",
            dataIndex: 'content',
            render: (text, record) =>
                <Link className='dialogue-BodyArea-List-Content' onClick={() => { dispatch(setDialogName(record.name)) }} to={'Content/' + record.id}>
                    <i className='icon-folder dialogue-BodyArea-List-Content-Icon' />
                    <p className='dialogue-BodyArea-List-Content-Text'>{GetWord("Contents")}</p>
                </Link>

        },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='dialogue-BodyArea-List-ButtonArea'>
                    <button className='dialogue-BodyArea-List-ButtonArea-DeleteButton' onClick={() => deleteDialog(record)} >
                        <i className='icon-dangerous dialogue-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='dialogue-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='dialogue-BodyArea-List-ButtonArea-EditButton' onClick={() => packageAdd(record)}>
                        <i className='icon-edit dialogue-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='dialogue-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];
    // const ListData = [
    //     {
    //         icon: '',
    //         name: '6-30',
    //         id: '1',
    //         type: 'Dialogue',
    //         content: 'İçerikler',
    //         button: '',

    //     },
    // ];


    const packageAdd = useCallback((value) => {
        setSelectedData(value);
        setIsModalOpen(true);
    }, []);

    const deleteDialog = useCallback((value) => {
        setSelectedData(value);
        setIsDeleteModalOpen(true);
    }, []);

    const noCancel = useCallback(() => {
        setIsModalOpen(false);
        setIsDeleteModalOpen(false);
    }, []);

    return (
        <div className='dialogue'>

            <div className='dialogue-HeaderArea'>
                <div className='dialogue-HeaderArea'>
                    <div className='dialogue-HeaderArea-PageName'>{GetWord("Dialogs")}</div>
                </div>
                <div className='dialogue-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search dialogue-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a dilaog")} />
                    <div className='dialogue-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => getDialogueList(search)}
                            className='dialogue-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='dialogue-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt dialogue-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
                <div className='dialogue-HeaderArea-AddUnit'>
                    <div className='dialogue-HeaderArea-AddUnit-Button'>
                        <>
                            <Button className='dialogue-HeaderArea-AddUnit-Button-Style' type="primary" onClick={() => packageAdd(null)}>
                                <p className='dialogue-HeaderArea-AddUnit-Button-Text'>{GetWord("Add Dialog")}</p>
                                <div className='dialogue-HeaderArea-AddUnit-Button-Icon'>
                                    <i className='icon-add' />
                                </div>
                            </Button>

                            <Modal destroyOnClose title="" open={isModalOpen} onCancel={noCancel} closable={false} className='dialogue'
                                footer={null}>
                                <DialogAdd selectedData={selectedData} setIsModalOpen={setIsModalOpen} setIsUpdate={setIsUpdate} />
                            </Modal>
                        </>

                    </div>
                </div>
            </div>


            <div className='dialogue-BodyArea'>
                <div className='dialogue-BodyArea-List'>
                    <Table
                        loading={{ spinning: loading, indicator: <LoadingPage small={true} /> }}
                        columns={List}
                        dataSource={listData}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>
            </div>
            <DeleteModal isModalOpen={isDeleteModalOpen} url="dialog" seletecedId={selectedData?.id} setIsReflesh={setIsUpdate} setIsModalOpen={setIsDeleteModalOpen} />
        </div>
    )
}

export default Dialogue